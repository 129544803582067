import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const ForgotPassword = Loadable(lazy(() => import('./ForgotPassword')));
const AdminLogin = Loadable(lazy(() => import('./AdminLogin')));

// const AdminRegister = Loadable(lazy(() => import('./AdminRegister')));
// const NotFound = Loadable(lazy(() => import('./NotFound')));

const AuthAdminRoutes = [
  { path: '/', element: <AdminLogin /> },
  { path: '/login', element: <AdminLogin /> },
  { path: '/forgot-password', element: <ForgotPassword /> },

  // { path: '/admin-register', element: <AdminRegister /> },
  // { path: '/session/404', element: <NotFound /> },
];

export default AuthAdminRoutes;
