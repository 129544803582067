import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../../auth/authRoles';


const PendingRegistrations = Loadable(lazy(() => import('./PendingRegistrations')));
const ApprovedRegistrations = Loadable(lazy(() => import('./ApprovedRegistrations')));
const RejectedRegistrations = Loadable(lazy(() => import('./RejectedRegistrations')));
const UserInfo = Loadable(lazy(() => import('./UserInfo')));
const ApprovedUserInfo = Loadable(lazy(() => import('./ApprovedUserInfo')));
const UpdateUserInfo = Loadable(lazy(() => import('./UpdateUserInfo')));
const UpdatePendingUserInfo = Loadable(lazy(() => import('./UpdatePendingUserInfo')));
const BlockedUserInfo= Loadable(lazy(() => import('./BlockedUserInfo')));


const UserProfile = Loadable(lazy(() => import('./UserProfile')));

const boardRoutes = [
    { path: 'dashboard/pending-registrations', element: <PendingRegistrations />, auth: authRoles.admin },
    { path: 'dashboard/approved-registrations', element: <ApprovedRegistrations />, auth: authRoles.admin },
    { path: 'dashboard/rejected-registrations', element: <RejectedRegistrations />, auth: authRoles.admin },
    { path: 'dashboard/user-info/:user_id', element: <UserInfo />, auth: authRoles.admin },
    { path: 'dashboard/blocked-user-info/:user_id', element: <BlockedUserInfo />, auth: authRoles.admin },
    { path: 'dashboard/approved-user-info/:user_id', element: <ApprovedUserInfo />, auth: authRoles.admin },
    { path: 'dashboard/update-user-info/:user_id', element: <UpdateUserInfo />, auth: authRoles.admin },
    { path: 'dashboard/update-pending-user-info/:user_id', element: <UpdatePendingUserInfo />, auth: authRoles.admin },
    { path: 'dashboard/user-profile/', element: <UserProfile />, auth: authRoles.admin },

];

export default boardRoutes;
