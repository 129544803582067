import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../../auth/authRoles';

const TransactionsV2 = Loadable(lazy(() => import('./TransactionsV2')));




const AccountsRoutes = [
    { path: '/dashboard/accounts/transaction-v2', element: <TransactionsV2 />, auth: authRoles.admin },



];

export default AccountsRoutes;