import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../../auth/authRoles';

const MasterAdmin = Loadable(lazy(() => import('./MasterAdmin')));
const SubAdmin = Loadable(lazy(() => import('./SubAdmin')));
const AddFeildExec = Loadable(lazy(() => import('./AddFeildExec')));
const FeildExecList = Loadable(lazy(() => import('./FeildExecList')))
const EditFeildExec = Loadable(lazy(() => import('./EditFeildExec')))




const HumanResourceRputes = [
    { path: '/dashboard/master-admin', element: <MasterAdmin />, auth: authRoles.admin },
    { path: '/dashboard/sub-admin', element: <SubAdmin />, auth: authRoles.admin },
    { path: '/dashboard/add-fe', element: <AddFeildExec />, auth: authRoles.admin },
    { path: '/dashboard/list-fe', element: <FeildExecList />, auth: authRoles.admin },
    { path: '/dashboard/edit-fe/:feId', element: <EditFeildExec />, auth: authRoles.admin },


];

export default HumanResourceRputes;