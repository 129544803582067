import AuthGuard from 'app/auth/AuthGuard';
// import chartsRoute from 'app/views/charts/ChartsRoute';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import userRoutes from 'app/views/admin/users/Routes';
import medicineCategoryRoutes from 'app/views/admin/medicine-category/Routes';
import medicineBrandRoutes from 'app/views/admin/medicine-brand/Routes';
import medicineRoutes from 'app/views/admin/medicine/Routes';
import HumanResourceRoutes from './views/admin/human-resource/HumanResourceRoutes';
import AccountsRoutes from './views/admin/accounts/AccountsRoutes';
import CouponsRoutes from './views/admin/coupons/CouponsRoutes';
import InvoiceRoutes from './views/admin/invoices/InvoiceRoutes';
import SettingsRoutes from './views/admin/applicationSetting/SettingsRoutes';
import UiRoutes from './views/admin/ui/UIRoutes';
import NotificationRoutes from './views/admin/notifications/NotificationRoutes';

import orderHistoryRoutes from 'app/views/admin/OrderHistory/OrderHistoryRoutes';

import NotFound from 'app/views/sessions/NotFound';

// import sessionRoutes from 'app/views/sessions/SessionRoutes';
import authAdminRoutes from 'app/views/auth/AuthAdminRoutes';

import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    // Route for admin login
    children: [
      ...dashboardRoutes,
      ...userRoutes,
      ...medicineCategoryRoutes,
      ...medicineBrandRoutes,
      ...medicineRoutes,
      ...orderHistoryRoutes,
      ...HumanResourceRoutes,
      ...AccountsRoutes,
      ...CouponsRoutes,
      ...InvoiceRoutes,
      ...SettingsRoutes,
      ...UiRoutes,
      ...NotificationRoutes,
    ],
  },

  ...authAdminRoutes,
  // { path: '/', element: <HomePage />},
  { path: '*', element: <Navigate to="dashboard" /> },
];

export default routes;
