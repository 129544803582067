import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../../auth/authRoles';

const MedicineBrandList = Loadable(lazy(() => import('./MedicineBrandList')));
const AddMedicineBrand = Loadable(lazy(() => import('./AddMedicineBrand')));
const EditMedicineBrand = Loadable(lazy(() => import('./EditMedicineBrand')));


const Routes = [
    { path: 'dashboard/medicine-brands/list', element: <MedicineBrandList />, auth: authRoles.admin },
    { path: 'dashboard/medicine-brand/add', element: <AddMedicineBrand />, auth: authRoles.admin },
    { path: 'dashboard/medicine-brand/edit/:id', element: <EditMedicineBrand />, auth: authRoles.admin },

];

export default Routes;
