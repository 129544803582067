import { useEffect } from 'react';
import '../fake-db';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { Store } from './redux/Store';
import routes from './routes';
import useAuth from 'app/hooks/useAuth';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_PATH;
// axios.defaults.baseURL ='/admin';

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';

axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('accessToken');
  // config.headers.Authorization = token ? `Bearer ${token}` : '';
  config.headers['x-api-key'] = token ? token : '';
  return config;
});

const App = () => {
  const content = useRoutes(routes);

  const { force_logout } = useAuth();

  const location = useLocation();

  useEffect(() => {
    window.addEventListener('click', check_authorisation);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('click', check_authorisation);
    }; // Call the authorization function
  }, []);

  useEffect(() => {
    // Add an event listener for beforeunload (page refresh)
    window.addEventListener('beforeunload', check_authorisation);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', check_authorisation);
    };
  }, []);

  const check_authorisation = () => {
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      let token = localStorage.getItem('accessToken');
      const config = { headers: { 'x-api-key': token } };
      axios
        .post('api/admin/account/compareAdminPassword', {}, config)
        .then((response) => {
          // console.log(response);
          if (response.data.status === 200) {
          } else {
            force_logout();
          }
        })
        .catch(function (error) {
          console.error(error);
          force_logout();
        });
      // });
    } catch (error) {
      console.error(error);
      force_logout();
    }
  };

  return (
    <Provider store={Store}>
      <SettingsProvider>
        <MatxTheme>
          <AuthProvider>{content}</AuthProvider>
        </MatxTheme>
      </SettingsProvider>
    </Provider>
  );
};

export default App;
