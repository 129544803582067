import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { MatxLoading } from 'app/components'
import swal from 'sweetalert';

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    } else {
        return true;
    }

    // const decodedToken = jwtDecode(accessToken)
    // const currentTime = Date.now() / 1000
    // return decodedToken.exp > currentTime
}

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        // delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload
            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload
            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'FORCELOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => { },
    force_logout:() => { },
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const navigate = useNavigate();
    const login = async (email, password) => {


        // axios.get('/sanctum/csrf-cookie').then(async response => {
        // await axios.post('/api/admin/login', {
        //     email: email,
        //     password: password,
        // }).then(response => {

        // if (response.status === 200) {
        // var responseData = response.data.data;
        const token = localStorage.getItem('accessToken');
        // localStorage.getItem('auth_name');
        const user = JSON.parse(localStorage.getItem('user'));
        //   console.log(token,user);
        // swal({
        //     title: "Good job!",
        //     text: response.data.message,
        //     icon: "success",
        // });
        // const { token, user } = responseData
        setSession(token)
        dispatch({
            type: 'LOGIN',
            payload: {
                user,
            },
        })
        navigate('/dashboard');
        // } else if (response.data.response_code === 401 || response.data.response_code === '401') {
        //     swal({
        //         title: "Warning!",
        //         text: response.data.message,
        //         icon: "warning",
        //     });
        // } else {
        // }

        // }).catch(function (error) {
        //     console.error(error);
        // });
        // });

    }


    const register = async (email, username, password) => {
        const response = await axios.post('/api/admin/register', {
            email,
            username,
            password,
        })

        const { accessToken, user } = response.data

        setSession(accessToken)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = () => {
        // axios.get('/sanctum/csrf-cookie').then(async response => {
        //     await axios.post('/api/admin/logout').then(response => {
        if (true) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('auth_name');
            localStorage.removeItem('user');
            swal({
                title: "Good job!",
                text: "Logged Out Successfully",
                icon: "success",
            });
            setSession(null)
            dispatch({ type: 'LOGOUT' })
        } else if (false) {
            swal({
                title: "Warning!",
                text: "Error",
                icon: "warning",
            });
        } else {
            swal({
                title: "Warning!",
                text: "Error",
                icon: "warning",
            });
        }
        // }).catch(function (error) {
        //     console.error(error);
        // });
        // });
    }

    const force_logout = () => {
        // axios.get('/sanctum/csrf-cookie').then(async response => {
        //     await axios.post('/api/admin/logout').then(response => {
        if (true) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('auth_name');
            localStorage.removeItem('user');

            setSession(null)
            dispatch({ type: 'FORCELOGOUT' })
        } else if (false) {
            swal({
                title: "Warning!",
                text: "Error",
                icon: "warning",
            });
        } else {
            swal({
                title: "Warning!",
                text: "Error",
                icon: "warning",
            });
        }
        // }).catch(function (error) {
        //     console.error(error);
        // });
        // });
    }

    useEffect(() => {
        ; (async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')
                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken)
                    axios.post(`/api/admin/profile`).then(response => {
                        if (true) {
                            // const user = response.data.data;
                            const data = localStorage.getItem('user');
                            // console.log(data);
                            const user = JSON.parse(data);
                            dispatch({
                                type: 'INIT',
                                payload: {
                                    isAuthenticated: true,
                                    user,
                                },
                            })
                        } else {
                            localStorage.removeItem('accessToken');
                            localStorage.removeItem('auth_name');
                            dispatch({
                                type: 'INIT',
                                payload: {
                                    isAuthenticated: false,
                                    user: null,
                                },
                            })
                        }
                    }).catch(function (error) {
                        console.log(error);
                        swal({
                            title: "Warning!",
                            text: error,
                            icon: "warning",
                        });
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('auth_name');
                        dispatch({
                            type: 'INIT',
                            payload: {
                                isAuthenticated: false,
                                user: null,
                            },
                        })
                    });
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
                force_logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
