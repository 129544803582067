export const navigations = [
  { name: 'Dashboard', path: '/dashboard', icon: 'dashboard' },
  // { label: 'Masters', type: 'label' },
  {
    name: 'UI',

    icon: 'money',
    children: [
      { name: 'Home Page ', iconText: 'SI', path: '/dashboard/ui/homepage' },

    ],
  },
  {
    name: 'Notifications',
    icon: 'person',
    children: [
      { name: 'Create & Send Notifications', iconText: 'SU', path: '/dashboard/notification/create-notification' },


    ],
  },
  {
    name: 'Registrations',
    icon: 'peopleOutline',
    children: [
      { name: 'Pending Registrations', iconText: 'SI', path: '/dashboard/pending-registrations' },
      { name: 'Approved Registrations', iconText: 'SI', path: '/dashboard/approved-registrations' },
      { name: 'Blocked Users', iconText: 'SI', path: '/dashboard/rejected-registrations' },
    ],
  },
  {
    name: 'Medicine',
    icon: 'store',
    children: [
      // { name: 'Overall Inventory', iconText: 'SI', path: '/dashboard/overall-inventorys/list' },
      { name: 'Approved Inventory', iconText: 'SI', path: '/dashboard/overall-inventorys/approved-list' },
      { name: 'Pending Inventory', iconText: 'SI', path: '/dashboard/overall-inventorys/pending-list' },
      { name: 'Bulk Upload', iconText: 'SI', path: '/dashboard/overall-inventorys/bulk-upload' },

      // { name: 'Vendor Wise', iconText: 'SU', path: '/dashboard/Vendor-wise/list' },
    ],
  },

  {
    name: 'Order History',
    icon: 'workHistory',
    children: [
      { name: 'Purchase', iconText: 'SI', path: '/dashboard/order-history/purchase/list' },
      { name: 'Order Details (Order Id)', iconText: 'SI', path: '/dashboard' },
    ],
  },
  {
    name: 'Invoice',
    icon: 'receipt',
    children: [
      { name: 'Hegan Settlement', iconText: 'SI', path: '/dashboard/hegan-settlement' },
    ],
  },

  {
    name: 'Coupons',
    icon: 'group',
    children: [
      { name: 'All Coupons', iconText: 'SI', path: '/dashboard/all-coupons' },
      { name: 'Add Coupon', iconText: 'SU', path: '/dashboard/add-coupons' },
    ],
  },
  {
    name: 'Stock',
    icon: 'inventory',
    children: [
      { name: 'Stock available', iconText: 'SI', path: '/dashboard' },
    ],
  },

  // {
  //   name: 'Accounts',
  //   icon: 'person',
  //   children: [
  //     { name: 'Ledger entry', iconText: 'SI', path: '/dashboard' },
  //     { name: 'Ledger List', iconText: 'SU', path: '/dashboard' },
  //     { name: 'Transactions-V2', iconText: 'SU', path: '/dashboard/accounts/transaction-v2' },
  //   ],
  // },
  {
    name: 'Reports',
    icon: 'summarize',
    children: [
      { name: 'Sale reports', iconText: 'SI', path: '/dashboard' },
      { name: 'Order Cancelled', iconText: 'SU', path: '/dashboard' },
      { name: 'Order Refund', iconText: 'SU', path: '/dashboard' },
    ],
  },
  {
    name: 'Tax',
    icon: 'money',
    children: [
      { name: 'All Tax', iconText: 'SI', path: '/dashboard' },
      { name: 'Add Tax', iconText: 'SU', path: '/dashboard' },
    ],
  },

  {
    name: 'Standard Commission',
    icon: 'paid',
    children: [
      { name: 'Medicine Categories', iconText: 'SI', path: '/dashboard/medicine-categories/list' },
      { name: 'Add Medicine Category', iconText: 'SU', path: '/dashboard/medicine-category/add' },
      { name: 'Medicine Brands', iconText: 'SI', path: '/dashboard/medicine-brands/list' },
      { name: 'Add Medicine Brands', iconText: 'SU', path: '/dashboard/medicine-brand/add' },
    ],
  },
  {
    name: 'Human resources',
    icon: 'person3',
    children: [
      { name: 'Master Admin', iconText: 'SI', path: '/dashboard/master-admin' },
      { name: 'Sub Admin', iconText: 'SU', path: '/dashboard/sub-admin' },
      { name: 'Add Field Executive', iconText: 'SU', path: '/dashboard/add-fe' },
      { name: 'Field Executive List', iconText: 'SU', path: '/dashboard/list-fe' },
    ],
  },
  {
    name: 'Application Setting',
    icon: 'settingsApplications',
    children: [
      { name: 'Setting', iconText: 'SI', path: '/dashboard' },
      { name: 'COD/Credit Cities', iconText: 'SI', path: '/dashboard/settings/cities' },
    ],
  },
  { name: 'Auto Update', path: '/dashboard', icon: 'autorenew' },
];
