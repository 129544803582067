import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../../auth/authRoles';

const PurchaseList = Loadable(lazy(() => import('./PurchaseList')));
const ViewPurchaseDetails = Loadable(lazy(() => import('./ViewPurchaseDetails')));
const ViewPurchaseDetail = Loadable(lazy(() => import('./ViewPurchaseDetail')));
const NewViewPurchaseDetail = Loadable(lazy(() => import('./NewViewPurchaseDetail')))

const OrderHistoryRoutes = [
  { path: '/dashboard/order-history/purchase/list', element: <PurchaseList />, auth: authRoles.admin },
  { path: '/dashboard/order-history/purchase-details/:order_id', element: <ViewPurchaseDetails />, auth: authRoles.admin },
  { path: '/dashboard/order-history/purchase-detail/:row_id', element: <ViewPurchaseDetail />, auth: authRoles.admin },
  { path: '/dashboard/order-history/new-purchase-detail/:row_id/:seller_id', element: <NewViewPurchaseDetail />, auth: authRoles.admin },

];

export default OrderHistoryRoutes;
