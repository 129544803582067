import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../../auth/authRoles';

const AddCoupons = Loadable(lazy(() => import('./AddCoupons')))
const AllCoupons = Loadable(lazy(() => import('./AllCoupons')));
// const EditCoupon = Loadable(lazy(() => import('./EditCoupon')));



const CouponsRoutes = [
    { path: '/dashboard/add-coupons', element: <AddCoupons />, auth: authRoles.admin },
    { path: '/dashboard/all-coupons', element: <AllCoupons />, auth: authRoles.admin },
    // {path: '/dashboard/all-coupons', element: <EditCoupon />, auth: authRoles.admin}
];

export default CouponsRoutes;