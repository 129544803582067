import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../../auth/authRoles';

const HeganSettlement = Loadable(lazy(() => import('./HeganSettlement')));




const InvoiceRoutes = [
    { path: '/dashboard/hegan-settlement', element: <HeganSettlement />, auth: authRoles.admin },
   


];

export default InvoiceRoutes;