import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../../auth/authRoles';

const HomePage = Loadable(lazy(() => import('./HomePage')));
const EditFormulation = Loadable(lazy(() => import('./EditFormulation')));
const EditSubCategory = Loadable(lazy(() => import('./EditSubCategory')))


const UiRoutes = [
    { path: '/dashboard/ui/homepage', element: <HomePage />, auth: authRoles.admin },
    { path: '/dashboard/ui/edit-formulation/:formuId', element: <EditFormulation />, auth: authRoles.admin },
    { path: '/dashboard/ui/edit-sub-category/:scatId', element: <EditSubCategory />, auth: authRoles.admin },

];

export default UiRoutes;