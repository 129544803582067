import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../../auth/authRoles';

const CreateNotifications = Loadable(lazy(() => import('./CreateNotifications')));
const EditNotification = Loadable(lazy(() => import('./EditNotification')));
const SendNotifications = Loadable(lazy(() => import('./SendNotifications')));



const NotificationRoutes = [
    { path: '/dashboard/notification/create-notification', element: <CreateNotifications />, auth: authRoles.admin },
    { path: '/dashboard/notification/edit-notification/:nId', element: <EditNotification />, auth: authRoles.admin },
    { path: '/dashboard/notification/send-notification/:nId', element: <SendNotifications />, auth: authRoles.admin },


];

export default NotificationRoutes;