import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../../auth/authRoles';

const EditMedicinesStock = Loadable(lazy(() => import('./EditMedicinesStock')));
const ViewMedicinesStock = Loadable(lazy(() => import('./ViewMedicinesStock')));
const ApprovedInventory = Loadable(lazy(() => import('./ApprovedInventory')))
const PendingInventory = Loadable(lazy(() => import('./PendingInventory')))
const BulkStockEntry = Loadable(lazy(() => import('./BulkStockEntry')));

const Routes = [
  { path: '/dashboard/medicine/edit-stock/:row_id', element: <EditMedicinesStock />, auth: authRoles.admin },
  { path: '/dashboard/medicine/view/:row_id', element: <ViewMedicinesStock />, auth: authRoles.admin },
  { path: '/dashboard/overall-inventorys/pending-list', element: <PendingInventory />, auth: authRoles.admin },
  { path: '/dashboard/overall-inventorys/approved-list', element: <ApprovedInventory />, auth: authRoles.admin },
  { path: '/dashboard/overall-inventorys/bulk-upload', element: <BulkStockEntry />, auth: authRoles.admin },
];

export default Routes;
