// import useSettings from 'app/hooks/useSettings';
const MatxLogo = ({ className }) => {
  // const { settings } = useSettings();
  // const theme = settings.themes[settings.activeTheme];

  return (
    <div>
      <img src={`${process.env.PUBLIC_URL}/assets/images/logos/Logo.png`} width="30px" alt='Hegan Logo'/>
    </div>
  );
};

export default MatxLogo;
