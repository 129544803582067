import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../../auth/authRoles';

const Cities = Loadable(lazy(() => import('./Cities')));
const EditCities = Loadable(lazy(() => import('./EditCities')));





const SettingsRoutes = [
    { path: '/dashboard/settings/cities', element: <Cities />, auth: authRoles.admin },
    { path: '/dashboard/settings/edit-cities/:cityId', element: <EditCities />, auth: authRoles.admin },
  


];

export default SettingsRoutes;