import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../../auth/authRoles';

const MedicineCategoryList = Loadable(lazy(() => import('./MedicineCategoryList')));
const AddMedicineCategory = Loadable(lazy(() => import('./AddMedicineCategory')));
const EditMedicineCategory = Loadable(lazy(() => import('./EditMedicineCategory')));


const Routes = [
    { path: 'dashboard/medicine-categories/list', element: <MedicineCategoryList />, auth: authRoles.admin },
    { path: 'dashboard/medicine-category/add', element: <AddMedicineCategory />, auth: authRoles.admin },
    { path: 'dashboard/medicine-category/edit/:id', element: <EditMedicineCategory />, auth: authRoles.admin },

];

export default Routes;
